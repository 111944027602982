:root {
    --primary-color: #E63946;
    --primary-color-light: #e639479f;

    --second-color: #A8DADC;
    --second-color-light: #a8dadc85;

    --button-color: #457B9D;
    --button-color-light: #457b9d71;

    --lateral-menu-color: #F1FAEE;

    --text-color-white: aliceblue;
    --text-color: #616161;
    --text-color-light: #616161c5;
    
    --dropzone-color: rgba(62, 62, 62, 0.23);
    --box-shadow-color: rgba(0, 0, 0, 0.3);
  }