.set_button_select {
  width: 70%;
  min-width: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #616161;
}

.set_button_select h1 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin: 20px;
}

.set_button_select h2 {
  max-height: 30vh;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin: 20px;
  overflow: hidden;
  overflow-y: scroll;
  text-align: justify;
}

.button_select_file {
  height: 12vh;
  width: 20vw;
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--button-color); /* Color de fondo cálido */
  color: #fff; /* Texto en color blanco */
  border: 2px solid var(--button-color); /* Borde del botón */
  border-radius: 15px; /* Esquinas redondeadas */
  text-decoration: none; /* Quita el subrayado de los enlaces */
  font-size:large;
  font-weight: bold; /* Texto en negrita */
  transition: background-color 0.5s, color 0.5s, transform 0.5s; /* Transiciones suaves al pasar el ratón */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.button_select_file:hover {
  background-color: var(--button-color-light); /* Color de fondo cálido más claro */
  color: #6a6666;
  transform: scale(1.1);
}

.button_and_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share-buttons{
  margin: 10px;
}

.share-buttons button{
  margin: 10px;
}

.share-buttons button:hover {
  background-color: #3498db; /* Cambia el color de fondo al pasar el ratón */
  color: #fff; /* Cambia el color del texto al pasar el ratón */
}

.share-buttons .facebook {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #3b5998;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/facebook.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

.share-buttons .twitter {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #55acee;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/twitter.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

.share-buttons .linkedin {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #0077b5;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/linkedin.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

@media (max-width: 767px) {
  .button_select_file {
    width: 80%;
    font-size: medium; /* Full width for mobile */
  }

  .set_button_select h1{
    font-size: 2.2rem;
  }

  .set_button_select h2{
    font-size: 1.2rem;
  }

}
