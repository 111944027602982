.selection_div {
  position: relative;
}

.scrollable_div {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 3px;
  margin-left: 20px;
  margin-right: 20px;
}

/* Style the scroll bar track */
/* Estiliza la pista de la barra de desplazamiento */
.scrollable_div::-webkit-scrollbar {
  height: 5px;
}

/* Estiliza el pulgar de la barra de desplazamiento (la parte arrastrable) */
.scrollable_div::-webkit-scrollbar-thumb {
  background: var(--second-color); /* Color de fondo del pulgar */
  border-radius: 10px; /* Redondeo de las esquinas del pulgar */
  border: none; /* Elimina el borde del pulgar */
}

.leftArrow {
  height: 40%;
  width: 50px;
  margin-left: 8px;
  background-image: url("../img/arrow.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center;
  background-color: transparent; /* Centers the background image */
  border: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  transition: ease-in-out 0.3s;
}

.rightArrow {
  height: 40%;
  width: 50px;
  margin-right: 8px;
  background-image: url("../img/arrow.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center;
  background-color: transparent; /* Centers the background image */
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  rotate: 180deg;
  transition: ease-in-out 0.3s;
  border-color:var(--second-color);
}

.leftArrow:hover, .rightArrow:hover {
  transform: scale(1.2);
  border: 2px solid var(--second-color);
  border-radius: 10px;
}

@media (max-width: 767px) {
  .leftArrow,
  .rightArrow {
    visibility: hidden;
  }
}
