.legal_cookies_div_father{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.legal_cookies_div{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 50px;
}

.legal_cookies_div h1{
    font-size: 2em;
    color: var(--text-color);
    font-weight: 600;
    margin: 10px;
}

.legal_cookies_div h2{
    font-size: 1.5em;
    color: var(--text-color);
    font-weight: 400;
    margin: 10px;
}

.legal_cookies_div h3{
    font-size: 1.2em;
    color: var(--text-color-light);
    font-weight: 400;
    margin: 5x;
}

.legal_cookies_div p{
    font-size: 1em;
    color: var(--text-color-light);
    font-weight: 400;
    margin: 5x;
}

