.ad_banner {
  height: 15vh;
}

.dropzone.dragging {
  background-color: var(--dropzone-color);
}

.pdf_preview_list_title{
  flex: 2;
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pdf_preview_title{
  text-size-adjust: auto;
}

.pdf_preview_title h1{
  margin: 10px;
  margin-top: 20px;
  font-size: 3vw;
  font-weight: 400;
  color: var(--text-color);
  width: 100%;
}

.pdf_preview_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 5px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.div_preview{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

/* Style the scroll bar track */
.pdf_preview_list::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Style the scroll bar thumb (the draggable part) */
.pdf_preview_list::-webkit-scrollbar-thumb {
  background: var(--second-color); /* Set the background color to blue */
  border: 5px solid var(--second-color); /* Optional: Add a border to the thumb */
  border-radius: 10px;
}

.container_select_properties_pdf {
  height: 100%;
}

.download_pdf {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reload_tag {
  margin: 10px;
  text-decoration: underline;
  color: var(--text-color-light);
}

.download_tag {
  visibility: hidden;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .dropzone {
    height: 100vh; /* Adjust height for mobile */
    /* max-height: 100vh; */
    min-width: auto;
    /* width: 50vw; Remove minimum width for mobile */
  }

  .container_select_properties_pdf {
    width: 40vw;
  }

  .button_select_file {
    width: 80%;
    font-size: medium; /* Full width for mobile */
  }

  .pdf_preview_list_title {
    flex: 1; /* Remove minimum width for mobile */
    min-width: 0;
  }

  .pdf_preview_title h1{
    margin: 0px;
  }

  .ad {
    display: none;
  }

  .ad_banner {
    display: none;
  }
}
