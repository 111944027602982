.dropzone {
    height: 90vh;
    min-width: 300px;
    width: 100%;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
    /* background:linear-gradient(to bottom, #3953a241, #6631a32a); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
  }