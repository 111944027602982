body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./img/Polygon\ Luminary.svg") no-repeat center center fixed;
  background-size: cover;
}

/* Style the scroll bar track */
*::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  height: 5px;
}

/* Style the scroll bar thumb (the draggable part) */
*::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* Set the background color to blue */
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
