.about_us{
    margin: 5vh 20vw 5vh 20vw;
    justify-content: center;
    align-items: center;
}

.about_us h1{
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2vw;
    color: var(--text-color);
}

.about_us h2{
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 2vw;
    color: var(--text-color);
    margin-top: 20px;
    text-align: justify;
}

.about_us h3{
    font-size: 1.7rem;
    font-weight: 400;
    margin-bottom: 2vw;
    color: var(--text-color);
    margin-top: 10px;
}

.about_us p{
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--text-color);
    margin-top: 5px;
    text-align: justify;
}

.img_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about_us img{
    width: 70%;
    height: 100%;
    margin-top: 5vh;
}