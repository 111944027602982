.principal_div_bubbles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding-top: 30px;
}

.principal_div {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 10px;
  padding-top: 30px;
  height: 100%;
}

.button_add_more_files {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: var(--primary-color);
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url("../img/plus.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  margin: 5px;
}

.button_a_to_z{
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  background-color: #fff;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url("../merge/img/sort-alpha-down.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  margin: 5px;
}

.button_z_to_a{
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  background-color: #fff;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url("../merge/img/sort-zeta-down.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  margin: 5px;
}

.button_div{
    display: block;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 80%;
    height: 50%;
}

.merge_button{
    margin-top: 50px;
    width: 100%;
    height: 70px;
    color: var(--text-color-white);
    border-color: transparent;
    background-color: var(--primary-color);
    cursor: pointer;
    border-radius: 13px;
    font-weight: bolder;
    font-size: large;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
    transition: color 0.5s, transform 0.5s;
}

.merge_button:hover {
    background-color: var(--primary-color-light);
    transform: scale(1.06);
  }
  
