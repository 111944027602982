.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.circle {
  width: 100px;
  height: 100px;
  border: 8px solid #000;
  border-top: 8px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.progress-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .circle {
    width: 60px; /* Further adjusted width for smaller screens */
    height: 60px; /* Further adjusted height for smaller screens */
    border: 4px solid var(--primary-color); /* Further adjusted border for smaller screens */
    border-top: 4px solid transparent; /* Further adjusted border for smaller screens */
  }

  .progress-text {
    margin-top: 5px; /* Further adjusted margin for smaller screens */
    font-size: 14px; /* Further adjusted font size for smaller screens */
  }
}
