.select_conversion_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
}

.select_conversion_menu label {
  font-size: 1.2rem;
  margin: 10px;
}

.convert_button {
  margin-top: 50px;
  width: 90%;
  height: 70px;
  color: var(--text-color-white);
  border-color: transparent;
  background-color: var(--primary-color);
  cursor: pointer;
  border-radius: 13px;
  font-weight: bolder;
  font-size: large;
  box-shadow: 1px 1px 7px var(--box-shadow-color);
  transition: color 0.5s, transform 0.5s;
}

.convert_button:hover {
  background-color: var(--primary-color-light);
  transform: scale(1.06);
}

#dropdownMenu, #dropdownMenuQuality{
  height: 5vh;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid var(--button-color);
  background-color: var(--lateral-menu-color);
  color: var(--text-color);
  font-weight: bold;
  cursor: pointer;
}