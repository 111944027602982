.explain_div_father{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.explain_div{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 50px;
}
.explain_div h1{
    font-size: 2em;
    color: var(--text-color);
    font-weight: 600;
}

.explain_div h3{
    font-size: 1.5em;
    color: var(--text-color-light);
    font-weight: 400;
    text-align: justify;
}