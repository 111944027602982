.footer_div {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_text {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  margin: 15px;
}

.div_footer_links {
  display: inline-block;
}

.footer_links {
  margin: 10px;
  text-decoration: underline;
  color: var(--text-color);
  overflow-wrap: break-word;
}

.cookie_consent{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-color);
  margin: 10px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .footer_text {
    font-size: 14px; /* Adjusted font size for mobile */
    margin: 10px; /* Adjusted margin for mobile */
  }

  .footer_links {
    margin: 5px; /* Adjusted margin for mobile */
  }
}
