.header {
  height: 70px;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  box-shadow: 5px 5px 10px var(--box-shadow-color);
  position: relative;
}
.header_left{
  height: 100%;
  flex-grow: 1;
  display: inline-block;
  align-items: center;
  justify-content: start;
  overflow:hidden;
  overflow-wrap: break-word;
}

.header_right{
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.logo {
  align-items: center;
  justify-content: center;
  color: var(--text-color-white);
  font-size: 24px;
  font-weight: bold;

  margin: 10px;
  text-decoration: none;
  height: 100%;
  width: auto;
}

.logo img{
  margin: 5px;
  height: 80%;
  width: auto;
}

.only_pdf_links{
  width: 140px;
  min-width: 140px;
  height: 50%;
  margin: 5px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  color: var(--text-color-white); /* White text color for contrast */
  text-decoration: none; /* Remove default underline */
  font-weight: 400; /* Make the text bold */
  font-size: 1.3rem; /* Adjust the font size */
  display: inline-block;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  align-items: center;
  position: relative; /* Needed for the ::after pseudo-element */
  overflow: hidden; /* Hide any content that overflows */
  white-space: nowrap;
}

.only_pdf_links:hover {
  transform: scale(1.1);
}

.only_pdf_links::after {
  content: ""; /* The content property is mandatory for ::after */
  position: absolute;
  bottom: 0; /* Position the line at the bottom of the element */
  left: 0;
  width: 100%; /* Make the line as wide as the element */
  height: 2px; /* Adjust the thickness of the line */
  background-color: var(--text-color-white); /* Make the line white */
  transform: scaleX(0); /* Initially, the line has no width */
  transition: transform 0.3s ease-in-out; /* Smooth width transition on hover */
}

.only_pdf_links:hover::after {
  transform: scaleX(1); /* When the element is hovered over, the line takes the full width */
}

.dropdown {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  position: relative;
}

.dropbtn{
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
  background-image: url('../img/hamb_menu.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center;
}

.dropdown:hover .dropbtn:after {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  max-width: 80vw;
  z-index: 1;
  background-color: var(--lateral-menu-color);
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: inline-block;

}

.list_menu{
  width: 150px;
  margin: 10px;
  justify-content: center;
}

.list_menu h1{
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 600;
}

.list_menu a{
  font-size: 0.9rem;
  color: var(--text-color-light);
  font-weight: 600;
  transition: ease-in-out 0.3s transform;
}

.list_menu a:hover{
  transform: scale(1.1);
  color: var(--button-color);
}

.direct_links{
  font-size: 1.1rem;
  color: var(--text-color);
  font-weight: 600;
  text-decoration: none;
  transition: ease-in-out 0.3s transform;
}

.direct_links:hover{
  transform: scale(1.1);
  color: var(--button-color);
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .title {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .header{
	width: 100%;
  justify-content: center;
  padding-left: 0px;
  }

  .header_button {
    font-size: 14px; /* Adjusted font size for mobile */
    padding: 10px; /* Adjusted padding for mobile */
  }

  .logo {
    height: 50%; /* Adjusted height for mobile */
  }

  .blog {
    font-size: 16px; /* Adjusted font size for mobile */
    margin-left: 5px; /* Adjusted margin for mobile */
  }
}
