.preview_container {
  margin: 10px;
  align-items: center;
  border: 1px solid var(--text-color-light); /* Add a light border to the image container */
  text-align: center; /* Center the content horizontally */
  background: linear-gradient(
    to bottom,
    #ffffff,
    var(--second-color-light)
  ); /* Add a light background color */
  box-shadow: 0 3px 4px var(--box-shadow-color); /* Add a subtle shadow */
  border-radius: 5px; /* Round the corners */
  display: block;
  padding: 5px;
  transition: background-color 0.5s, color 0.5s, transform 0.5s;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.preview_container:hover {
  background: linear-gradient(
    to bottom,
    #ffffff,
    var(--second-color)
  ); /* Add a light background color */
  color: var(--text-color);
  transform: scale(1.03);
}

.p_preview {
  height: 10%;
  margin: 0;
  padding: 0;
  color: #888;
  font-weight: bold;
}

.preview_separator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  font-weight: bold;
  font-size: 0.85rem;
}

.preview_separator img {
  height: auto;
  width: 14vw;
  min-width: 150px;
  margin: 3px;
  align-items: center;
  justify-content: center;
}
.preview_separator p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
}

.preview_separator_line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.delete_range_button {
  height: 100%;
  width: 100%;
}

.rotate_range_button {
  height: 100%;
  width: 100%;
}

.delete_range_tooltip {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.8em;
  height: 1.8em;
  margin: 4px;
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  background-image: url("../img/cross.svg");
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  background-size: cover; /* Adjusts the background image size */
  background-position: center;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.rotate_range_tooltip {
  position: absolute;
  top: 0;
  right: left;
  width: 1.8em;
  height: 1.8em;
  margin: 4px;
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  background-image: url("../img/rotate.svg");
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  background-size: cover; /* Adjusts the background image size */
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  z-index: 1;
}


.preview_image {
  flex: 1;
  max-width: auto; /* Ensure the image doesn't exceed its original size */
  height: 90%;
  object-fit: contain;
  border: 2px solid #ccc;
}

.preview_image_svg {
  flex: 1;
  max-width: auto; /* Ensure the image doesn't exceed its original size */
  height: 60%;
  object-fit: contain;
  /* Optional: Add a border for clarity */
}

@media (max-width: 767px) {
  .preview_separator img {
    min-width: 100px;
  }
}
