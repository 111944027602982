.ad_banner {
  height: 15vh;
}

.dropzone.dragging {
  background-color: #888;
  color: aliceblue;
}

.ad {
  flex: 1;
  width: 100%;
  background-color: transparent;
}

.div_preview{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

/* Style the scroll bar track */
.pdf_preview_list::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Style the scroll bar thumb (the draggable part) */
.pdf_preview_list::-webkit-scrollbar-thumb {
  background: #ff6b6b; /* Set the background color to blue */
  border: 5px solid #ff6b6b; /* Optional: Add a border to the thumb */
}

.container_select_properties_pdf {
  height: 100%;
}

.download_pdf {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reload_tag {
  margin: 10px;
  text-decoration: underline;
  color: #888;
}

.download_tag {
  visibility: hidden;
}

.drop_it{
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  font-size: 14px; /* Adjust the font size */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .dropzone {
    height: 100vh; /* Adjust height for mobile */
    /* max-height: 100vh; */
    min-width: auto;
    /* width: 50vw; Remove minimum width for mobile */
  }

  .container_select_properties_pdf {
    width: 40vw;
  }

  .button_select_file {
    width: 80%;
    font-size: medium; /* Full width for mobile */
  }

  .pdf_preview_list {
    min-width: auto; /* Remove minimum width for mobile */
  }

  .ad {
    display: none;
  }

  .ad_banner {
    display: none;
  }
}
