.select_tool_button{
    height: 25vh;
    width: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    border: 2px solid transparent;
}

.image{
    height: 60%;
    width: auto;
    transition: transform 0.3s ease-in-out;
}

.select_tool_button label{
    margin-top: 10px;
    height: 20%;
    font-size: 1.2em;
    font-weight: 600;
    color:#616161;
    text-align: center;
    cursor: pointer;
}

.label{
    transition: transform 0.3s ease-in-out;
}

.select_tool_button:hover{
    background-color: var(--second-color-light);
    border-color:var(--second-color);
    cursor: pointer;
    /* transform: scale(1.1); */
}

.select_tool_button:hover .image {
    transform: scale(1.1);
}

.select_tool_button:hover .label {
    color:#434343;
}