.select_pdf_container {
  position: relative;
  height: 95%;
  width: 30vw;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to top, #ffffff, var(--lateral-menu-color));
  border-style: solid;
  border-image: linear-gradient(to bottom, var(--second-color),  #ffffff) 1;
  border-right: none;
  overflow: hidden;
}

.select_container {
  max-height: 100%;
  height: 70%;
  width: 90%;
  overflow-y: auto;
  border-radius: 10px;
  height: fit-content;
  background-color: transparent;
  /* justify-content: center; */
  align-items: center;
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.division_container {
  height: 90px;
  width: 90%;
  min-width: 235px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: var(--button-color) solid 1px;
  border-radius: 10px;
  margin: 3px;
  position: relative;
}

.delete_range_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  transition: transform 0.3s;
  cursor: pointer;
}

.delete_range_button:hover {
  transform: scale(1.06);
}

.delete_img {
  height: 100%;
  width: 100%;
}

.for_range {
  height: 30%;
  display: flex;
  color: var(--text-color);
  font-weight: bold;
  align-items: center;
}

.from_to_container {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* border-bottom: 2px solid #ccc; */
  padding: 10px;
}

.from_to {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label_from_to {
  width: 50px;
  color: var(--text-color-light);
  font-weight: 450;
}

.input_from_to {
  flex: 1;
}

/* Estilos para el input number */
input[type="number"] {
  width: 40px; /* Ancho del input */
  padding: 10px; /* Espaciado interno */
  border: 1px solid var(--button-color); /* Borde */
  border-radius: 5px; /* Bordes redondeados */
  font-size: 16px; /* Tamaño de fuente */
  text-align: center; /* Texto centrado */
  outline: none; /* Elimina el borde de enfoque */
}

/* Estilos para los botones de incremento y decremento */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0; /* Elimina el espacio entre el input y los botones */
}

/* Estilo cuando el input está enfocado */
input[type="number"]:hover {
  border: 1px solid var(--primary-color); /* Borde azul al enfocar */
  box-shadow: 0 0 5px var(--primary-color); /* Sombra al enfocar */
}

/* Style the scroll bar track */
.select_container::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  height: 5px;
}

/* Style the scroll bar thumb (the draggable part) */
.select_container::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* Set the background color to blue */
  border-radius: 10px;
}

.from_to {
  flex: 1;
}

.add_new_division {
  border: 2px;
  width: 45%;
  min-width: 90px;
  height: 35px;
  border: var(--primary-color-light) solid 2px;
  border-radius: 4px;
  box-shadow: 1px 1px 7px var(--box-shadow-color);
  background: aliceblue;
  color: var(--primary-color);
  font-weight: bold;
  margin: 10px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.5s, transform 0.5s; /* Transiciones suaves al pasar el ratón */
}

.add_new_division:hover {
  color: var(--primary-color-light);
  border-color: var(--primary-color);
  transform: scale(1.06);
}

.img_plus {
  height: 100%;
}

.div_split {
  width: 80%;
  height: 30%;
  max-height: 50px;
  min-height: 50px;
}

.split_button {
  width: 100%;
  height: 100%;
  color: azure;
  border-color: transparent;
  background-color: var(--primary-color);
  cursor: pointer;
  border-radius: 13px;
  font-weight: bolder;
  font-size: large;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  transition: color 0.5s, transform 0.5s;
}

.split_button:hover {
  background-color: var(--primary-color-light);
  transform: scale(1.06);
}

.select_ranges {
  width: 90%;
  max-height: 60px;
  display: flex;
}

.button_ranges {
  flex: 3;
  margin: 5px;
  border: gray solid 2px;
  border-radius: 4px;
  box-shadow: 1px 1px 7px var(--box-shadow-color);
  background: aliceblue;
  color: gray;
  font-weight: bold;
  margin: 10px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.5s, transform 0.5s;
}

.button_ranges.active {
  flex: 4;
  margin: 5px;
  border: var(--primary-color) solid 2px;
  border-radius: 4px;
  box-shadow: 1px 1px 7px var(--box-shadow-color);
  background: var(--lateral-menu-color);
  color: var(--primary-color);
  font-weight: bold;
  margin: 10px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.5s, transform 0.5s;
}

.button_ranges:hover {
  color: var(--primary-color-light);
  border-color: var(--primary-color-light);
  transform: scale(1.06);
}

.container_fixed_ranges {
  display: flex;
  padding: 10px;
}

.input_fixed_ranges {
  margin: 8px;
}

.container_fixed {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
}

.input_ranges {
  min-width: 100px;
  max-width: 100px;
  margin: 5px;
  border: 2px solid var(--button-color);
  border-radius: 5px;
}

.checkbox {
  margin: 5px;
}

.label_check {
  color: #888;
  font-weight: bold;
  margin: 20px;
}

.has_error {
  display: flex;
  flex-direction: column;
}

.error-input {
  min-width: 100px;
  max-width: 100px;
  margin: 5px;
  border-radius: 5px;
  border: 2px solid red;
  color: red;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .select_pdf_container {
    flex: 1;
    min-width: 0;
  }

  .select_ranges {
    height: 100%;
  }
  .select_container {
    max-height: 100%;
    width: 90%;
  }

  .division_container {
    height: auto; /* Adjusted height for smaller screens */
    margin: 3px 0; /* Adjusted margin for smaller screens */
    width: 95%;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .from_to_container {
    padding: 5px; /* Adjusted padding for smaller screens */
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .add_new_division {
    width: 70%; /* Adjusted width for smaller screens */
    min-width: 70px; /* Adjusted min-width for smaller screens */
    margin: 5px; /* Adjusted margin for smaller screens */
  }

  .div_split {
    height: 50px; /* Adjusted height for smaller screens */
    min-height: 50px; /* Adjusted min-height for smaller screens */
  }

  .split_button {
    font-size: medium; /* Adjusted font size for smaller screens */
  }

  .button_ranges {
    flex: 4; /* Adjusted flex for smaller screens */
  }

  .input_fixed_ranges,
  .input_ranges,
  .error-input {
    min-width: 80px; /* Adjusted min-width for smaller screens */
    max-width: 80px; /* Adjusted max-width for smaller screens */
  }
}
