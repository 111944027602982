.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq h1{
  font-size: 2.8rem;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--text-color);
  margin: 20px;
  text-align: center
}

.faq h2{
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--text-color);
  margin: 20px;
  width: 60%;
  text-align: justify;
}

.faq_dropdown {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.faq_dropdown_showed {
  width: 100%;
  min-width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 3px solid var(--button-color);
  color: var(--text-color);
  transition: border 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
}

.faq_dropdown_showed:hover{
  color: var(--button-color);
  border: 3px solid var(--primary-color);
  transform: scale(1.01);
}

.faq_dropdown_showed h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 15px;
  margin-left: 30px;
}

.faq_dropbtn {
  height: 30px;
  width: 30px;
  background-color: transparent;
  transition: transform 0.3s ease, rotate 0.5s ease;
  background-image: url("../img/plus_faq.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  margin-right: 30px;
}

.faq_dropbtn.minus {
  height: 30px;
  width: 30px;
  background-color: transparent;
  transition: transform 0.3s ease, rotate 0.5s ease;
  background-image: url("../img/minus_faq.svg");
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  margin-right: 30px;
}

.faq_dropdown:hover .faq_dropbtn {
  transform: scale(1.1);
  rotate: 180deg;
}

.faq_dropdown_content {
  margin-left: 40px;
  margin-right: 40px;
  opacity: 0; /* Start invisible */
  max-height: 0; /* Start with no height */
  overflow: hidden; /* Hide content that overflows the max-height */
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease, margin-top 0.5s ease, margin-bottom 0.5s ease; /* Transition opacity and max-height */
}

.faq_dropdown_content.show {
  margin-top: 30px;
  margin-bottom: 20px;
  opacity: 1; /* End visible */
  max-height: 500px; /* End with a large height */
}

.faq_dropdown_content p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--text-color);
  text-align: justify;
}
