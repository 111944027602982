@import "../../colours.css";

.select_tool {
  width: auto;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf_row_div {
  height: auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-top: 40px;
}

.pdf_row_div label {
  font-size: 1.5em;
  font-weight: 700;
  color: var(--text-color);
  text-align: left;
}

.pdf_label {
  margin: 15px;
}

.selection_div {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 2px 8px var(--box-shadow-color);
  overflow: hidden;
}

.others{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  overflow-x: scroll;
}

.others_buttons{
  min-width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ot_label{
  font-size: 1.5em;
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
  margin-top: 10px;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;

}

.ot_button{
  width: 20vh;
  height: 20vh;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: white;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-size: 80%; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
}

#faq_ot{
  background-image: url('../img/faq_icon.svg');
}

#about_us_ot{
  background-image: url('../img/faq_about-us.svg');
}

.ot_button:hover{
  background-color: var(--text-color-white);
  box-shadow: 0 0 0 3px var(--second-color);
  transform: scale(1.07);
}

.ot_button:hover ~ .ot_label{
  color: var(--button-color);
  transform: scale(1.07);
}

@media (max-width: 600px) { /* Adjust 600px to the width at which you want the items to stack */
  .others {
    flex-direction: column;
  }
}