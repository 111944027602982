.lateral_div {
  flex:1;
  position: relative;
  height: 95%;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to top, transparent, var(--lateral-menu-color));
  border-style: solid;
  border-image: linear-gradient(to bottom, var(--second-color), #ffffff) 1;
  border-right: none;
  overflow: hidden;
}


@media (max-width: 767px) {
    .lateral_div {
      flex: 1;
      min-width: 0;
    }
}